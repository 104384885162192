import * as React from 'react'
import {Button} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {Spinner} from "fragments/Spinner";

export interface ComponentProps {
    iconClassName: string
    labelTKey: string
    submitting: boolean
    disabled: boolean
    buttonClassName?: string
    onSubmit: () => void
}

class _Component extends React.PureComponent<ComponentProps & WithTranslation> {

    render() {
        const { t, buttonClassName, iconClassName, disabled, labelTKey, submitting, onSubmit } = this.props;
        return <Button className={"configure text-uppercase text-left button-height-48 d-flex align-items-center " + buttonClassName}
                       onClick={() => !submitting&&onSubmit()}
                       disabled={disabled||false}>
            <Spinner active={submitting||false} stillStyle={"fa-lg pr-2 " + iconClassName}/>
            <div className={"d-inline pl-2"}>{t(labelTKey)}</div>
        </Button>

    }
}

export const AsyncButton = withTranslation('common')(_Component);