import {FetchAction, FetchState} from "services";
import {ActionType} from "types";

export interface State {
    id: number
    type: string
    price: number
    allow_more: boolean
}

export function reducer(state: State[] | null = null, action: FetchAction) {
    switch (action.state) {
        case FetchState.SUCCESS: {
            switch (action.type) {
                case ActionType.FETCH_MEMBERSHIPS: {
                    return action.payload;
                }
                default:
                    return state;
            }
        }
        default:
            return state
    }
}