import { createSelector } from 'reselect'
import {ApplicationState} from "store";
import * as events from "services/reducers/events";

const getSeasonEvents = (state:ApplicationState) => state.data.events || [];

export const getVisibleSeasonEvents = createSelector(
    [ getSeasonEvents ],
    (e: events.State[]) => {
        return e;
    }
)