import {connectRoutes, LocationState} from "redux-first-router";
import {applyMiddleware, combineReducers, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk'
import {logger} from 'redux-logger'
import {Keys, routePaths} from 'routes'
import {initialState as apiInitialState, reducer as apiReducer, State as ApiState} from "services";
import {ContactData, initialState as contactDataInitialState, reducer as contactDataReducer} from "contactData";
import {State as MenuState} from 'react-burger-menu';
import {reducer as burgerMenu} from 'redux-burger-menu';
import {
    initialState as registrationDataInitialState,
    reducer as registrationDataReducer,
    RegistrationStateData
} from "registrationData";

const {
    reducer: routeReducer,
    middleware: routeMiddleware,
    enhancer: routeEnhancer
} = connectRoutes(routePaths);

//devtools
const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

/*
 * This is the root state of the app
 * It contains every substate of the app
 */
export interface ApplicationState {
    location?: LocationState<Keys>
    contactData: ContactData
    registrationData: RegistrationStateData
    data: ApiState
    burgerMenu: MenuState
}

/*
 * initialState of the app
 */
export function initialState(): ApplicationState {
    return {
        data: apiInitialState,
        contactData: contactDataInitialState,
        registrationData: registrationDataInitialState,
        burgerMenu: { isOpen:false }
    };
}

export function configureStore () {
    return createStore(
        combineReducers<ApplicationState>({
            // @ts-ignore
            location: routeReducer,
            data: apiReducer,
            contactData: contactDataReducer,
            registrationData: registrationDataReducer,
            burgerMenu
        }),
        initialState(),
        composeEnhancers(routeEnhancer, applyMiddleware(routeMiddleware, thunkMiddleware, logger))
    );
}
