import * as React from 'react'
import { connect } from 'react-redux'
import {Card, CardText, Col, Label, Row} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {ApplicationState} from "store";
import {NATURAL_SORT_COMPARE_OPTIONS} from "types";
import * as press from "services/reducers/press";
import {PageHeader} from "components/PageHeader";
import {PageBody} from "components/PageBody";
import PdfDialog, {PdfDialogData} from "./PdfDialog";
import {useState} from "react";
import moment from "moment";
import {Button, Image} from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';

interface StateToProps {
    press?: press.State[] | null
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
    press: state.data.press
});

const _Component: React.FC<StateToProps & WithTranslation> = (props) => {

    const [modalData, setModalData] = useState<PdfDialogData|null>(null);
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return <React.Fragment>
    <Card>
        <PageHeader titleKey={'content.press-title'}/>
        <PageBody>
            {
                props.press&&props.press
                    .sort((f1, f2) => -f1.datum.localeCompare(f2.datum, undefined, NATURAL_SORT_COMPARE_OPTIONS))
                    .map((press, index) => {
                        const datum: moment.Moment = moment(press.datum);
                        const isLastEvent: boolean = index === props.press!!.length-1;
                        const buttonSize = isMobile?"sm":undefined
                        const buttonWidthHeight = isMobile?"40px":"50px"
                        return <Row key={press.id} className={(isLastEvent?'':'bodyslice ') + 'm-0 pt-2 pb-2'}>
                            <Col className={"p-0 col-2 test-left key-text d-flex align-items-center"}>
                                <div className={"w-100"}>
                                    <Label className={"m-0 entity-center"}>{datum.format('DD.MM.YYYY')}</Label>
                                    <div className={"w-100 pt-1 d-flex entity-center"}>
                                        <Image src={"ZoZu.jpg"} width={"50px"} alt="Zumiker Zolliker Bote"></Image>
                                    </div>
                                </div>
                            </Col>
                            <Col className={isMobile?"col-9 test-left key-text":"col-8 test-left key-text"}>
                                <Label className={"w-100 m-0 title-text"}>{press.title}</Label>
                                <CardText className={"w-100 mb-2"}>{press.text}</CardText>
                                <Label className={"w-100 m-0"}>{"Von " + press.author}</Label>
                            </Col>
                            <Col className={"p-0 col-1 test-left key-text d-flex align-items-center"}>
                                <div>
                                    <Button variant={"primary"}
                                            size={buttonSize}
                                            style={{width: buttonWidthHeight, height: buttonWidthHeight, alignContent: "center", backgroundColor: "#0433ff"}}
                                            className={"far fa-glasses"}
                                            onClick={() => setModalData({
                                                title: press.title,
                                                url: press.article.data.url,
                                                dismiss: setModalData
                                            })}>
                                    </Button>
                                    {
                                        isMobile?
                                            <Button variant={"primary"}
                                                    size={buttonSize}
                                                    style={{width: buttonWidthHeight, height: buttonWidthHeight, alignContent: "center", backgroundColor: "#0433ff"}}
                                                    className={"mt-2 far fa-link"}
                                                    onClick={() => window.open(press.link||undefined, "_blank")}>
                                            </Button> : null
                                    }
                                </div>
                            </Col>
                            {
                                isMobile? null:
                                <Col className={"p-0 col-1 test-left key-text d-flex align-items-center"}>
                                    <Button variant={"primary"}
                                            size={buttonSize}
                                            style={{width: buttonWidthHeight, height: buttonWidthHeight, alignContent: "center", backgroundColor: "#0433ff"}}
                                            className={"far fa-link"}
                                            onClick={() => window.open(press.link||undefined, "_blank")}>
                                    </Button>
                                </Col>
                            }
                        </Row>
                    })
            }
        </PageBody>
    </Card>
        {
            modalData ? <PdfDialog {...modalData}/> : null
        }
    </React.Fragment>;
}

export const Press = connect(mapStateToProps, null)(withTranslation('common')(_Component));