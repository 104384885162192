export function createReducer(initialState: any, handlers: any) {
    return function reducer(state = initialState, action: any) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action)
        } else {
            return state
        }
    }
}

export function asBoolean(value: any): boolean {
    const boolVal: boolean = value;
    return boolVal;
}
export function asString(value: any): string {
    const strVal: string = value;
    return strVal;
}