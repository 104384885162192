import * as React from 'react'
import { connect } from 'react-redux'
import {
    Card,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {ApplicationState} from "store";
import {AsyncButton} from "components/AsyncButton";
import {submitRegistration} from "services";
import {PageHeader} from "components/PageHeader";
import {PageBody} from "components/PageBody";
import {registrationFormChange, registrationFormReady, RegistrationStateData} from "../registrationData";
import {computeAllowSubmit, RegistrationForm, validateForm} from "./RegistrationForm";
import {Image} from "react-bootstrap";

interface StateToProps {
    registrationData: RegistrationStateData
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
    registrationData: state.registrationData
});

interface DispatchToProps {
    onRegistrationChange: (data:Object) => void
    onRegistrationSubmit: () => void
    submitRegistration: () => void
    editRegistration: () => void
}

const mapDispatchToProps = (dispatch: any) : DispatchToProps => ({
    onRegistrationChange: (data:Object) => dispatch(registrationFormChange(data)),
    onRegistrationSubmit: () => dispatch(registrationFormReady(true)),
    submitRegistration: () => dispatch(submitRegistration()),
    editRegistration: () => dispatch(registrationFormReady(false))
});

class _Component extends React.PureComponent<StateToProps & DispatchToProps & WithTranslation> {

    render() {
        const {t, registrationData, onRegistrationSubmit, editRegistration, submitRegistration} = this.props;
        return <React.Fragment>
            <Card>
                <PageHeader titleKey={'content.registration-title'}/>
                <PageBody>
                    <Label className={'w-100 m-0 text-uppercase title-text pt-3'}>{t('content.event-registration-title')}</Label>
                    <RegistrationForm registrationData={registrationData}/>
                    <Row className="no-gutters py-2 d-print-none">
                        <Col className="px-0 col-md-6 align-self-center">
                            {registrationData.formSubmitted ?
                                null :
                                <AsyncButton
                                    disabled={registrationData.formSubmitting || !computeAllowSubmit(this.props)}
                                    iconClassName="far fa-check-circle" buttonClassName='w-100'
                                    submitting={registrationData.formSubmitting} labelTKey='registration.do'
                                    onSubmit={() => validateForm(this.props) && onRegistrationSubmit()}/>
                            }
                        </Col>
                    </Row>
                    <Row className="no-gutters px-0 col-md-12 pt-4">
                        <Col className="px-0 col-md-12">
                            <Image src={"twint.png"} alt="twint" width={"100%"}></Image>
                        </Col>
                    </Row>
                </PageBody>
            </Card>
            {
                registrationData.formReady ?
                    <Modal isOpen={!registrationData.formSubmitted}>
                        <ModalHeader>
                            <div className={"title-normal-uppercase"}>{t('registration.sendtitle')}</div>
                        </ModalHeader>
                        <ModalBody>{t('registration.sendquestion')}</ModalBody>
                        <ModalFooter>
                            <AsyncButton iconClassName="far fa-edit" disabled={registrationData.formSubmitting}
                                         submitting={false} labelTKey='registration.continueEdit'
                                         onSubmit={() => editRegistration()}/>
                            <AsyncButton iconClassName="far fa-check-circle" disabled={registrationData.formSubmitting} buttonClassName='primary'
                                         submitting={registrationData.formSubmitting} labelTKey='registration.sendOk'
                                         onSubmit={() => submitRegistration()}/>
                        </ModalFooter>
                    </Modal> :
                    null
            }
        </React.Fragment>
    }
}

export const Registration = connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(_Component));