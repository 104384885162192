import * as React from 'react'
import {Card} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {PageHeader} from "components/PageHeader";
import {PageBody} from "components/PageBody";
import {ApplicationState} from "store";
import {connect} from "react-redux";
import ReactMarkdown from "react-markdown";

interface StateToProps {
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
});

const content =
    'Wir verwenden keine Cookies, so werden auf Ihrem Endgerät von uns keine Cookies gespeichert. Bei der Deaktivierung von Cookies in Ihrem Browser bleibt die Funktionalität dieser Website uneingeschränkt.\n' +
    '#### Hosting Provider & Server-LogFiles\n' +
    'Der Provider der Seiten kann automatisch Informationen in sogenannten Server-Log Files speichern, die Ihr Browser automatisch an uns übermittelt. Dies sind:\n' +
    '- IP-Adresse\n' +
    '- Browsertyp und Browserversion\n' +
    '- verwendetes Betriebssystem\n' +
    '- Referrer URL\n' +
    '- Hostname des zugreifenden Rechners\n' +
    '- Uhrzeit der Serveranfrage\n' +
    '\n' +
    'Diese Daten sind nicht direkt bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.\n' +
    '\n' +
    'Diese Daten sowie alle Daten dieser Website werden bei unserem Hosting-Provider Hetzner Online GmbH, Sigmundstraße 135, 90431 Nürnberg gespeichert.\n' +
    '#### SSL-Verschlüsselung\n' +
    'Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.\n' +
    '\n' +
    'Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.\n' +
    '#### Mitgliedschaftsformular\n' +
    'Wenn Sie uns per Mitgliedschaftsformular eine Anmeldung zukommen lassen, werden Ihre Angaben aus dem Mitgliedschaftsformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage bei uns gespeichert. Die Kontaktdaten werden automatisch per E-Mail zum verantwortlichen Vorstandsmitglied weitergeleitet. Die gesamten Kontaktdaten werden dann in einem Excel-File verwaltet. Diese Daten geben wir nicht weiter.\n' +
    '#### Google Analytics\n' +
    'Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.\n' +
    '\n' +
    'Google Analytics verwendet so genannte „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.\n' +
    '#### IP Anonymisierung\n' +
    'Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.\n' +
    '#### Browser Plugin\n' +
    'Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de\n' +
    '\n' +
    'Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: https://support.google.com/analytics/answer/6004245?hl=de\n' +
    '#### Demografische Merkmale bei Google Analytics\n' +
    'Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem GoogleKonto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.\n' +
    '#### Recht auf Auskunft, Löschung, Sperrung\n' +
    'Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an ein Mitglied des Vorstands wenden.'

class _Component extends React.PureComponent<StateToProps & WithTranslation> {

    render() {
        return <Card>
            <PageHeader titleKey={'content.privacy-title'}/>
            <PageBody>
                <ReactMarkdown className="w-100 align-middle light pt-2" source={content} />
            </PageBody>
        </Card>;
    }
}

export const Privacy = connect(mapStateToProps, null)(withTranslation('common')(_Component));
