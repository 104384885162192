import * as React from 'react'
import {CardBody} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";

class _Component extends React.PureComponent<WithTranslation> {
    render() {
        const {children} = this.props;
        return <CardBody className="p-3 normal-text">{children}</CardBody>;
    }
}

export const PageBody = withTranslation('common')(_Component);