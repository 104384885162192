import {FetchAction, FetchState} from "services";
import {ActionType} from "types";

export interface State {
    id: number,
    newspaper: string,
    datum: string,
    author: string,
    title: string,
    text: string,
    link: string | null,
    article:  {
        data: {
            full_url: string,
            url: string
        }
    },
}

export function reducer(state: State[] | null = null, action: FetchAction) {
    switch (action.state) {
        case FetchState.SUCCESS: {
            switch (action.type) {
                case ActionType.FETCH_PRESS: {
                    return action.payload
                }
                default:
                    return state;
            }
        }
        default:
            return state
    }
}