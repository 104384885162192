import * as React from 'react'
import {RefObject} from 'react'
import {connect} from 'react-redux'
import {Card} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {ApplicationState} from "store";
import * as actual from "services/reducers/actual";
import {Document, Page} from 'react-pdf';
import {PageHeader} from "components/PageHeader";
import ReactMarkdown from "react-markdown";

interface ComponentState {
    numPages: number
    width: number
}

interface StateToProps {
    actual?: actual.State | null
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
    actual: state.data.actual
});

class _Component extends React.Component<StateToProps & WithTranslation, ComponentState> {

    divRef: RefObject<HTMLDivElement>;

    constructor(props: StateToProps & WithTranslation, context: any) {
        super(props, context);
        this.state = {
            numPages: -1,
            width: -1
        };
        this.divRef = React.createRef()
    }

    shouldComponentUpdate(nextProps: Readonly<StateToProps & WithTranslation>, nextState: Readonly<ComponentState>) {
        return !this.props.actual || !nextProps.actual || !this.props.actual.detail_file || !nextProps.actual.detail_file ||
            ( this.props.actual.detail_file.id!==nextProps.actual.detail_file.id ) ||
            ( this.props.actual.detail_file.checksum!==nextProps.actual.detail_file.checksum ) ||
            ( this.state.numPages!==nextState.numPages ) ||
            (this.state.width!==nextState.width);
    }

    render() {
        const {actual} = this.props;
        return <Card>
            <PageHeader titleKey={'content.actual-title'}/>
            <div ref={this.divRef} className={"px-3"}>
                {
                    actual && actual.cancelled && actual.cancelled_info?
                        <ReactMarkdown className="w-100 px-3 px-md-5 info-text pt-4"
                                       source={
                                           (actual.cancelled_info||'')
                                               .replace(/(?:\r|\\r)/g, ' ')
                                               .replace(/(?:\n|\\n)/g, '<br>')
                                       }
                                       escapeHtml={false} /> :
                        null
                }
                {
                    actual && actual.detail_file && actual.detail_file.data && actual.detail_file.data.url?
                        <Document file={{url: actual.detail_file.data.url}}
                                  onLoadSuccess={ pdf => this.setState({
                                      numPages: pdf.numPages||0,
                                      width: (this.divRef.current&&this.divRef.current.offsetWidth-32)||0
                                  })}>
                            {
                                this.state.numPages > 0 ? <Page pageNumber={1} width={this.state.width}/> : null
                            }
                            {
                                this.state.numPages > 1 ? <Page pageNumber={2} width={this.state.width}/> : null
                            }
                        </Document>:
                        null
                }
            </div>
        </Card>;
    }
}

export const Actual = connect(mapStateToProps, null)(withTranslation('common')(_Component));