import {FetchAction, FetchState} from "services";
import {ActionType} from "types";

export interface State {
    house_nr: string | null,
    firstname: string,
    chairman: boolean,
    lastname: string,
    role: string,
    city: string
    email: string | null
    street: string
    zipcode: string
    picture: {
        private_hash: string
    }
}

export function reducer(state: State[] | null = null, action: FetchAction) {
    switch (action.state) {
        case FetchState.SUCCESS: {
            switch (action.type) {
                case ActionType.FETCH_BOARD: {
                    return action.payload
                }
                default:
                    return state;
            }
        }
        default:
            return state
    }
}