import {FetchAction, MembershipData} from "services";
import {ActionType, VALUE_NORMALIZED_SUFFIX} from "types";
import {asString} from "helper";
import {formatPhoneNumberIntl} from 'react-phone-number-input'

// Form for a contact configuration
export interface ContactData {
    contact: MembershipData
    formReady: boolean
    formSubmitting: boolean
    formSubmitted: boolean
    submissionError: boolean
    subscribed?: boolean
}

export const initialState: ContactData = {
    contact: {},
    formReady:false,
    formSubmitting: false,
    formSubmitted: false,
    submissionError: false
};

export enum FormField {
    SUBSCRIPTION = 'subscription',
    TITLE = 'title',
    NAME = 'name',
    STREET = 'street',
    HOUSE_NR = 'house_nr',
    ADDRESS_COMPLEMENT = 'address_complement',
    ZIPCODE = 'zipcode',
    CITY = 'city',
    EMAIL = 'email',
    PHONE = 'phone',
    COMMENTS = 'comments'
}

function normalizeFieldValue(field: FormField, value: string | boolean): string | boolean{
    switch (field) {
        case FormField.PHONE:
            return formatPhoneNumberIntl(asString(value));
        default:
            return value?asString(value).trim():value;
    }
}

export function buildFormField(field: FormField, value: any): object {
    return {
        [field]: value,
        [field + VALUE_NORMALIZED_SUFFIX]: normalizeFieldValue(field, value)
    }
}

export function reducer(state: ContactData = initialState, action: FetchAction | FormAction) {
    switch (action.type) {
        case ActionType.CONTACT_FORMCHANGE: {
            return {
                ...state,
                contact : {
                    ...state.contact,
                    ...action.payload
                }
            }
        }
        case ActionType.CONTACT_FORMREADY: {
            return {
                ...state,
                formReady: action.payload.formReady
            }
        }
        case ActionType.CONTACT_FORMSUBMITTING: {
            return {
                ...state,
                formSubmitting: true
            }
        }
        case ActionType.CONTACT_FORMSUBMITTED: {
            return {
                ...state,
                formSubmitting: false,
                formSubmitted: true,
                subscribed: true
            }
        }
        case ActionType.CONTACT_FORMCANNOTSUBMIT: {
            return {
                ...state,
                formSubmitting: false,
                formSubmitted: false,
                submissionError: true
            }
        }
        default:
            return state
    }
}

function normalizedForm(form: Object): MembershipData {
    return Object.keys(form)
        .filter(key => key.endsWith(VALUE_NORMALIZED_SUFFIX))
        .reduce((obj, key) => {
            return {
                ...obj,
                [key.slice(0, -VALUE_NORMALIZED_SUFFIX.length)]: form[key]
            };
        }, {}) as MembershipData;
}

export function buildMembershipData(form: ContactData | undefined): MembershipData {
    return form&&form.formReady?normalizedForm(form.contact):{}
}

interface FormAction
{
    type: ActionType.CONTACT_FORMCHANGE | ActionType.CONTACT_FORMREADY | ActionType.CONTACT_FORMSUBMITTING | ActionType.CONTACT_FORMSUBMITTED | ActionType.CONTACT_FORMCANNOTSUBMIT
    payload: object
}

export function contactFormChange(data: Object): FormAction {
    return { type: ActionType.CONTACT_FORMCHANGE, payload: { ...data } }
}

export function contactFormReady(ready:boolean): FormAction {
    return { type: ActionType.CONTACT_FORMREADY, payload: { formReady: ready } }
}

export function contactFormSubmitting(): FormAction {
    return { type: ActionType.CONTACT_FORMSUBMITTING, payload: { } }
}

export function contactFormSubmitted(subscribed?: boolean): FormAction {
    return { type: ActionType.CONTACT_FORMSUBMITTED, payload: { subscribed } }
}

export function contactFormCannotSubmit(): FormAction {
    return { type: ActionType.CONTACT_FORMCANNOTSUBMIT, payload: { } }
}