import * as React from 'react'
import {Card, Col, Row} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import { FacebookProvider, Like } from 'react-facebook';
import {PageHeader} from "components/PageHeader";
import {PageBody} from "components/PageBody";
import { SocialIcon } from 'react-social-icons';
import {ApplicationState} from "store";
import * as organisation from "services/reducers/organisation";
import {connect} from "react-redux";
import ReactMarkdown from "react-markdown";

interface StateToProps {
    organisation?: organisation.State | null
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
    organisation: state.data.organisation
});

class _Component extends React.PureComponent<StateToProps & WithTranslation> {

    render() {
        const {t, organisation} = this.props;
        return <Card>
            <PageHeader/>
            <PageBody>
                {
                    organisation && organisation.announce && organisation.announce.trim()?
                        <ReactMarkdown className="w-100 px-md-5 info-text pt-4"
                                       source={(organisation.announce||'').replace(/(?:\r|\n|\\r|\\n)/g, ' ')}
                                       escapeHtml={false} /> :
                        null
                }
                <div className={"px-md-5"}>
                    <Row className={"d-flex justify-content-start no-gutters pt-3 pt-md-4 pb-4 title-style text-uppercase"}>
                        {t('content.concerts-title')}
                    </Row>
                    <Row className={"d-flex justify-content-center no-gutters py-4 title-style text-uppercase"}>
                        <div className="col-12 col-md-5 text-center text-lg-right">
                        {t('content.encounters-title')}
                        </div>
                        <div className="col-0 col-lg-2 col-xl-3">{' '}
                        </div>
                    </Row>
                    <Row className={"justify-content-end no-gutters pt-4 pb-2 pb-md-4 title-style text-uppercase"}>
                        {t('content.visits-title')}
                    </Row>
                </div>
                <div className={"px-md-5 extra-padding-top"}>
                    <Row className={"no-gutters py-5"}>
                        <Col className={"copl-12 col-md-9"}>
                            <span className="cit-style">{t('content.frontword')}</span>
                        </Col>
                        <Col className={"col-12 col-md-3 text-right pt-2 pt-md-0"}>
                            <span className="cit-style">{t('content.frontword-author')}</span>
                        </Col>
                    </Row>
                </div>
                <div className={"px-md-5 extra-padding-top"}>
                    <Row className={"d-flex justify-content-center no-gutters pt-md-4"}>
                        <SocialIcon network="facebook" style={{ height: 28, width: 28 }} className={"mr-3"}/>
                        <FacebookProvider appId="Ij3Wp8lg5Kz" xfbml>
                            <Like href="https://www.facebook.com/zumikerkulturkreis" colorScheme="light" size={"large"} layout={"button_count"}/>
                        </FacebookProvider>
                        <a className="normal-text" target="zumikerkulturkreis" href={"https://www.facebook.com/zumikerkulturkreis"}>Facebook</a>
                    </Row>
                </div>
                <div className={"px-md-5"}>
                    <Row className={"d-flex justify-content-center no-gutters pb-md-4 pt-4"}>
                        <SocialIcon network="instagram" style={{ height: 28, width: 28 }} className={"mr-3"}/>
                        <a className="normal-text" target="zumikerkulturkreis" href={"https://www.instagram.com/zumiker_kulturkreis"}>Instagram</a>
                    </Row>
                </div>
                <div className={'px-md-5'}>
                    <Row className={"d-flex justify-content-center no-gutters version-text pb-md-1 pt-4"}>
                        {t('header.version', {version: process.env.REACT_APP_VERSION})}
                    </Row>
                </div>
            </PageBody>
        </Card>;
    }
}

export const Home = connect(mapStateToProps, null)(withTranslation('common')(_Component));
