import * as React from 'react'
import { connect } from 'react-redux'
import {CardHeader, CardTitle} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";

interface ComponentProps {
    titleKey?: string
    title?: string
}

class _Component extends React.PureComponent<ComponentProps & WithTranslation> {
    render() {
        const {t, titleKey, title} = this.props;
        return <CardHeader className={"px-3"}><CardTitle className={'m-0 pl-6 pl-lg-0 text-uppercase header-text'}>{titleKey?t(titleKey):title?title:t('header.title')}</CardTitle></CardHeader>;
    }
}

export const PageHeader = connect(null, null)(withTranslation('common')(_Component));