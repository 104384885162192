import i18next from "i18next";
import LngDetector from 'i18next-browser-languagedetector';
import de from 'translations/de/common.json';
import {NUMBER_FORMAT_DECIMAL} from "types";
import numeral from "numeral";
import {initReactI18next} from "react-i18next";
import 'moment/locale/de-ch'
import moment from "moment";

export enum Language { de = 'de'}

// FIXME register supplemental locales?
numeral.register('locale', 'de-ch', {
    delimiters: {
        thousands: '\'',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function (number) {
        return '.';
    },
    currency: {
        symbol: 'CHF'
    }
});
numeral.locale('de-ch');
moment.locale('de-ch');

i18next
    .use(LngDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        fallbackLng: Language.de,
        debug: true,
        interpolation: {
            escapeValue: false,
            formatSeparator: ';',
            format: function(value, formatting, lng){
                return numeral(value).format(formatting?formatting:NUMBER_FORMAT_DECIMAL);
            }
        },
        resources: {
            de: {
                common: de
            }
        },
        react: {
            wait: false,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        },
        defaultNS: 'common',
        detection: {
            order: ['querystring', 'navigator'],
            lookupQuerystring: 'lng'
        }
    });

export default i18next;