import {FetchAction, FetchState} from "services";
import {ActionType} from "types";

export interface State {
    id: number
    title: string
    description: string
    cancelled: boolean
    postponed: boolean
    location: {
        id: number
        name: string
        street: string | null
        street_nr: string | null
        zipcode: string
        city: string
        map: {
            lat: number,
            lng: number
        }
    }
    only_members: boolean
    requires_registration: boolean
    online_registration: boolean
    season: number
    when: string
    whole_month: boolean,
    actual: boolean
}

export function reducer(state: State[] | null = null, action: FetchAction) {
    switch (action.state) {
        case FetchState.SUCCESS: {
            switch (action.type) {
                case ActionType.FETCH_EVENTS: {
                    return action.payload;
                }
                default:
                    return state;
            }
        }
        default:
            return state
    }
}