import * as React from 'react'

interface StateToProps {
    active: boolean
    stillStyle?: string
}

export class Spinner extends React.PureComponent<StateToProps> {

    render () {
        const {active,stillStyle} = this.props;
        return (
            <div className="spinner d-inline-block d-flex align-items-center">
            {
                active?
                    <React.Fragment>
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </React.Fragment>
                   :<i className={stillStyle||"fas fa-check spinner-done"}></i>
            }
            </div>);
    }
}