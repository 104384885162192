import {FetchAction, RegistrationData} from "services";
import {ActionType, VALUE_NORMALIZED_SUFFIX} from "types";
import {asString} from "helper";
import {formatPhoneNumberIntl} from 'react-phone-number-input'

// Form for a registration configuration
export interface RegistrationStateData {
    registration: RegistrationData
    formReady: boolean
    formSubmitting: boolean
    formSubmitted: boolean
    submissionError: boolean
    subscribed?: boolean
}

export const initialState: RegistrationStateData = {
    registration: {
        count_members: 0,
        count_non_members: 0,
        amount: 0
    },
    formReady:false,
    formSubmitting: false,
    formSubmitted: false,
    submissionError: false
};

export enum RegistrationFormField {
    TITLE = 'title',
    NAME = 'name',
    ADDRESS = 'address',
    ZIPCODE = 'zipcode',
    CITY = 'city',
    EMAIL = 'email',
    PHONE = 'phone',
    COUNT_MEMBERS = 'count_members',
    COUNT_NON_MEMBERS = 'count_non_members',
    AMOUNT = 'amount',
    COMMENTS = 'comments'
}

function normalizeFieldValue(field: RegistrationFormField, value: string | boolean): string | boolean{
    switch (field) {
        case RegistrationFormField.PHONE:
            return formatPhoneNumberIntl(asString(value));
        default:
            return value?asString(value).trim():value;
    }
}

export function buildFormField(field: RegistrationFormField, value: any): object {
    return {
        [field]: value,
        [field + VALUE_NORMALIZED_SUFFIX]: normalizeFieldValue(field, value)
    }
}

export function reducer(state: RegistrationStateData = initialState, action: FetchAction | FormAction) {
    switch (action.type) {
        case ActionType.REGISTRATION_FORMCHANGE: {
            const registration = {
                ...state.registration,
                ...action.payload
            };
            const amount = registration.count_members * 100 + registration.count_non_members * 120;
            return {
                ...state,
                registration : {
                    ...registration,
                    amount: amount,
                    amount$norm: amount
                }
            }
        }
        case ActionType.REGISTRATION_FORMREADY: {
            const registration = {
                ...state.registration
            };
            registration['count_members$norm'] = registration.count_members
            registration['count_non_members$norm'] = registration.count_non_members
            return {
                ...state,
                registration: {
                    ...registration
                },
                formReady: action.payload.formReady
            }
        }
        case ActionType.REGISTRATION_FORMSUBMITTING: {
            return {
                ...state,
                formSubmitting: true
            }
        }
        case ActionType.REGISTRATION_FORMSUBMITTED: {
            return {
                ...state,
                formSubmitting: false,
                formSubmitted: true,
                subscribed: true
            }
        }
        case ActionType.REGISTRATION_FORMCANNOTSUBMIT: {
            return {
                ...state,
                formSubmitting: false,
                formSubmitted: false,
                submissionError: true
            }
        }
        default:
            return state
    }
}

function normalizedForm(form: Object): RegistrationData {
    return Object.keys(form)
        .filter(key => key.endsWith(VALUE_NORMALIZED_SUFFIX))
        .reduce((obj, key) => {
            return {
                ...obj,
                [key.slice(0, -VALUE_NORMALIZED_SUFFIX.length)]: form[key]
            };
        }, {}) as RegistrationData;
}

export function buildRegistrationData(form: RegistrationStateData | undefined): RegistrationData {
    return form&&form.formReady?normalizedForm(form.registration):{
        count_members: 0,
        count_non_members: 0,
        amount: 0
    }
}

interface FormAction
{
    type: ActionType.REGISTRATION_FORMCHANGE | ActionType.REGISTRATION_FORMREADY | ActionType.REGISTRATION_FORMSUBMITTING | ActionType.REGISTRATION_FORMSUBMITTED | ActionType.REGISTRATION_FORMCANNOTSUBMIT
    payload: object
}

export function registrationFormChange(data: Object): FormAction {
    return { type: ActionType.REGISTRATION_FORMCHANGE, payload: { ...data } }
}

export function registrationFormReady(ready:boolean): FormAction {
    return { type: ActionType.REGISTRATION_FORMREADY, payload: { formReady: ready } }
}

export function registrationFormSubmitting(): FormAction {
    return { type: ActionType.REGISTRATION_FORMSUBMITTING, payload: { } }
}

export function registrationFormSubmitted(subscribed?: boolean): FormAction {
    return { type: ActionType.REGISTRATION_FORMSUBMITTED, payload: { subscribed } }
}

export function registrationFormCannotSubmit(): FormAction {
    return { type: ActionType.REGISTRATION_FORMCANNOTSUBMIT, payload: { } }
}