import * as React from 'react'
import {ReactNode} from 'react'
import {connect} from 'react-redux'
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {
    DEFAULT_COUNTRIES, NATURAL_SORT_COMPARE_OPTIONS,
    VALUE_ERROR_SUFFIX,
    VALUE_NORMALIZED_SUFFIX
} from "types";
import {buildFormField, ContactData, contactFormChange, FormField} from "contactData";
import {Map} from 'immutable';
import {InputType} from "reactstrap/lib/Input";
import {ApplicationState} from "store";
import * as EmailValidator from 'email-validator';
import {asBoolean, asString} from "helper";
import PhoneInput from 'react-phone-number-input'
import {isValidPhoneNumber} from 'react-phone-number-input'
import * as memberships from "services/reducers/memberships";

interface ComponentProps {
    showLabel: Map<string, boolean>
}

interface StateToProps {
    contactData: ContactData;
    memberships: memberships.State[] | null;
}

interface DispatchToProps {
    onMembershipChange: (data: Object) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
    onMembershipChange: (data: Object) => dispatch(contactFormChange(data))
});

const mapStateToProps = (state: ApplicationState, props: StateToProps): ComponentProps => ({
    showLabel: computeInputLabels(state.contactData.contact),
});

function computeInputLabels(form: Object): Map<string, boolean> {
    var showLabel: Map<string, boolean> = Map<string, boolean>();
    for (let field in FormField) {
        if (form[FormField[field]]) {
            showLabel = showLabel.set(FormField[field], true);
        }
    }
    return showLabel;
}

function isFieldInvalid(field: FormField, form: Object): boolean {
    return !!form[field + VALUE_ERROR_SUFFIX];
}

function isFieldMandatory(form: Object, field: FormField): boolean {
    switch (field) {
        case FormField.TITLE:
        case FormField.ADDRESS_COMPLEMENT:
        case FormField.COMMENTS:
            return false;
        default:
            return true;
    }
}

function isFieldVisible(field: FormField): boolean {
    switch (field) {
        default:
            return true;
    }
}

function validateFieldValue(props: ContactFormValidationProps, field: FormField, value: string | boolean): string | undefined {
    const {t} = props;
    switch (field) {
        case FormField.EMAIL:
            return value && !EmailValidator.validate(asString(value)) ? t('membership.invalidemail') :
                validateMandatoryField(props, field, asString(value));
        case FormField.PHONE:
            return value && !isValidPhoneNumber(value as string) ? t('membership.invalidphone') :
                validateMandatoryField(props, field, asString(value));
        default:
            return validateMandatoryField(props, field, asString(value));
    }
}

function validateMandatoryField(props: ContactFormValidationProps, field: FormField, value: string): string | undefined {
    const {t, contactData} = props;
    return isFieldMandatory(contactData.contact, field) && !value ? t('membership.missingvalue') : undefined;
}

class _Component extends React.PureComponent<StateToProps & ComponentProps & DispatchToProps & WithTranslation> {

    constructor(props: StateToProps & ComponentProps & DispatchToProps & WithTranslation, context: any) {
        super(props, context);
        this.formField = this.formField.bind(this);
        this.fieldData = this.fieldData.bind(this);
        this.reportField = this.reportField.bind(this);
        this.resolveRelations = this.resolveRelations.bind(this);
    }

    /**
     * Utility to build the a form field
     * @param field
     * @param inputType
     * @param rowCount
     */
    formField(field: FormField, inputType: InputType, rowCount?: number): ReactNode {
        const {t, contactData, onMembershipChange, showLabel, memberships} = this.props;
        const label: string = t('membership.' + field);
        const invalid: boolean = isFieldInvalid(field, contactData.contact);
        const mandatory: boolean = isFieldMandatory(contactData.contact, field);
        return <React.Fragment>
            {invalid ?
                <label htmlFor={field}
                       className={"form-label mb-1 show error"}>{contactData.contact[field + VALUE_ERROR_SUFFIX]}</label> :
                inputType === 'checkbox' ?
                    null :
                    <label htmlFor={field}
                           className={"form-label mb-1 " + (showLabel.get(field) ? 'show' : '')}>{label}</label>
            }
            {
                field === FormField.PHONE ?
                    <PhoneInput id={field}
                                placeholder={t('membership.field' + (mandatory ? 'required' : 'optional'), {field: label})}
                                className={"form-control text-normal-details form-control-lg " + (invalid ? "field-error" : "")}
                                readOnly={false}
                                value={asString(contactData.contact[field])}
                                defaultCountry={'CH'}
                                countryOptionsOrder={DEFAULT_COUNTRIES}
                                onChange={(value: string) => onMembershipChange(this.fieldData(field, value))}/> :
                    field === FormField.SUBSCRIPTION ?
                        memberships&&
                        <Input type="select" id={field}
                               placeholder={t('membership.field' + (mandatory ? 'required' : 'optional'), {field: label})}
                               className={"form-control text-normal-details form-control-lg " + (invalid ? "field-error" : "")}
                               readOnly={false}
                               defaultValue={contactData.contact[field]?asString(contactData.contact[field]):""}
                               required={true}
                               onChange={(e) => onMembershipChange(this.fieldData(field, e.target.value))}>
                            <option hidden disabled value="" className={"select-placeholder"}>{t('membership.field' + (mandatory ? 'required' : 'optional'), {field: label})}</option>
                            {
                                memberships
                                    .sort((a, b) => a.type.localeCompare(b.type, undefined, NATURAL_SORT_COMPARE_OPTIONS))
                                    .map((m: memberships.State) => (
                                        <option key={m.id} value={m.id}>{m.type}</option>
                                    ))
                            }
                        </Input>:
                        inputType === 'textarea' ?
                            <textarea id={field}
                                      placeholder={t('membership.field' + (mandatory ? 'required' : 'optional'), {field: label})}
                                      className={"form-control text-normal-details form-control-lg " + (invalid ? "field-error" : "")}
                                      readOnly={false}
                                      value={asString(contactData.contact[field])}
                                      onChange={(e) => onMembershipChange(this.fieldData(field, e.target.value))}
                                      rows={rowCount}/> :
                            inputType === 'checkbox' ?
                                <React.Fragment>
                                    <Input type={'checkbox'} id={field} className={""}
                                           checked={asBoolean(contactData.contact[field])}
                                           onChange={(e) => onMembershipChange(this.fieldData(field, e.target.checked))}/>
                                    <Label className="form-check-label" htmlFor={field}>{label}</Label>
                                </React.Fragment> :
                                <Input type={inputType} id={field}
                                       placeholder={t('membership.field' + (mandatory ? 'required' : 'optional'), {field: label})}
                                       className={"form-control text-normal-details form-control-lg " + (invalid ? "field-error" : "")}
                                       readOnly={false}
                                       value={asString(contactData.contact[field])}
                                       onChange={(e) => onMembershipChange(this.fieldData(field, e.target.value))}/>
            }
        </React.Fragment>
    }

    fieldData(field: FormField, value: string | boolean): Object {
        const error: string | undefined = validateFieldValue(this.props, field, value);
        return {
            ...buildFormField(field, value),
            // Add error part
            [field + VALUE_ERROR_SUFFIX]: error
        }
    }

    resolveRelations(field: FormField, value: any): any {
        const {memberships} = this.props;
        if (field === FormField.SUBSCRIPTION) {
            const membership: memberships.State | undefined | null = value&&memberships&&memberships.find(m => m.id===value);
            return membership&&membership.type;
        } else {
            return value;
        }
    }

    reportField(field: FormField): ReactNode {
        const {t, contactData} = this.props;
        const value: any = contactData.contact[field + VALUE_NORMALIZED_SUFFIX];
        const resolved: any = this.resolveRelations(field, value);
        return resolved ?
                <Row className="no-gutters pt-2">
                    <Col className={"col-sm-3"}><Label
                        className={"report-label"}>{t('membership.' + field)}</Label></Col>
                    <Col className={"col-sm-9 text-normal-details text-pre-wrap"}>{resolved}</Col>
                </Row> :
            null
    }

    render() {
        const {contactData, t} = this.props;
        return <React.Fragment>
            <Row className="no-gutters normal-text pt-2">
                <Label>{t(contactData.formSubmitted ? 'membership.submittedtext' : 'membership.text')}</Label>
            </Row>
            {contactData.formSubmitted ?
                <React.Fragment>
                    {this.reportField(FormField.SUBSCRIPTION)}
                    {this.reportField(FormField.TITLE)}
                    {this.reportField(FormField.NAME)}
                    {this.reportField(FormField.STREET)}
                    {this.reportField(FormField.HOUSE_NR)}
                    {this.reportField(FormField.ADDRESS_COMPLEMENT)}
                    {this.reportField(FormField.ZIPCODE)}
                    {this.reportField(FormField.CITY)}
                    {this.reportField(FormField.EMAIL)}
                    {this.reportField(FormField.PHONE)}
                    {this.reportField(FormField.COMMENTS)}
                </React.Fragment> :
                <Form>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(FormField.SUBSCRIPTION, "select")}</FormGroup>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(FormField.TITLE, "text")}</FormGroup>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(FormField.NAME, "text")}</FormGroup>
                    <Row className="no-gutters">
                        <FormGroup className={"col-10 pr-sm-2 mb-2 normal-text"}>{this.formField(FormField.STREET, "text")}</FormGroup>
                        <FormGroup className={"col-2 pl-sm-2 mb-2 normal-text"}>{this.formField(FormField.HOUSE_NR, "text")}</FormGroup>
                    </Row>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(FormField.ADDRESS_COMPLEMENT, "text")}</FormGroup>
                    <Row className="no-gutters">
                        <FormGroup
                            className={"col-sm-3 pr-sm-2 mb-2 normal-text"}>{this.formField(FormField.ZIPCODE, "number")}</FormGroup>
                        <FormGroup
                            className={"col-sm-9 pl-sm-2 mb-2 normal-text"}>{this.formField(FormField.CITY, "text")}</FormGroup>
                    </Row>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(FormField.EMAIL, "email")}</FormGroup>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(FormField.PHONE, "tel")}</FormGroup>
                    <FormGroup className="mb-2 normal-text">{this.formField(FormField.COMMENTS, "textarea", 5)}</FormGroup>
                </Form>
            }
        </React.Fragment>
    }
}

export interface ContactFormStateProps extends StateToProps {
}

export interface ContactFormValidationProps extends ContactFormStateProps, WithTranslation, DispatchToProps {
}

export function computeAllowSubmit(props: ContactFormStateProps): boolean {
    const {contactData} = props;
    return Object.keys(FormField).map(key => FormField[key]).reduce((prev: boolean, curr: FormField) =>
        prev && (!isFieldVisible(curr) || !isFieldInvalid(curr, contactData.contact)), true)
}

export function validateForm(props: ContactFormValidationProps): boolean {
    const {contactData, onMembershipChange} = props;
    var validationObject: object =
        Object.keys(FormField).map(key => FormField[key]).reduce((prev: Object, curr: FormField) => {
            var value: string | boolean | undefined = contactData.contact[curr];
            const error: string | undefined = validateFieldValue(props, curr, (value && value.toString()) || '');
            return error ? {...prev, [curr + VALUE_ERROR_SUFFIX]: error} : prev;
        }, {});
    onMembershipChange(validationObject);
    return Object.keys(validationObject).length === 0;
}

export const ContactForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(_Component));