import {FetchAction, FetchState} from "services";
import {ActionType} from "types";

export interface State {
    id: number,
    cancelled: boolean,
    cancelled_info: string | null
    detail_file: {
        id: number,
        checksum: string,
        data: {
            full_url: string,
            url: string
        } | null
    } | null
}

export function reducer(state: State | null = null, action: FetchAction) {
    switch (action.state) {
        case FetchState.SUCCESS: {
            switch (action.type) {
                case ActionType.FETCH_ACTUAL_FILE: {
                    return (action.payload&&action.payload.length&&action.payload[0])||null;
                }
                default:
                    return state;
            }
        }
        default:
            return state
    }
}