import {FetchAction, FetchState} from "services";
import {ActionType} from "types";

export interface State {
    id: number,
    name: string
}

export function reducer(state: State | null = null, action: FetchAction) {
    switch (action.state) {
        case FetchState.SUCCESS: {
            switch (action.type) {
                case ActionType.FETCH_SEASONS: {
                    return action.payload;
                }
                default:
                    return state;
            }
        }
        default:
            return state
    }
}