import * as React from 'react'
import { connect } from 'react-redux'
import {Card, Row} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {ApplicationState} from "store";
import {NATURAL_SORT_COMPARE_OPTIONS} from "types";
import * as archive from "services/reducers/archive";
import {PageHeader} from "components/PageHeader";
import {PageBody} from "components/PageBody";
import PdfDialog, {PdfDialogData} from "./PdfDialog";
import {useState} from "react";

interface StateToProps {
    archive?: archive.State[] | null
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
    archive: state.data.archive
});

const _Component: React.FC<StateToProps & WithTranslation> = (props) => {

    const [modalData, setModalData] = useState<PdfDialogData|null>(null);

    return <React.Fragment>
    <Card>
        <PageHeader titleKey={'content.archive-title'}/>
        <PageBody>
            <div className={"d-flex flex-column align-items-center"}>
            {
                props.archive&&props.archive
                    .sort((f1, f2) => -f1.title.localeCompare(f2.title, undefined, NATURAL_SORT_COMPARE_OPTIONS))
                    .map((arch, index) => {
                        return <Row key={arch.id} className={"no-gutters py-2"}>
                            <a className="normal-text" target="zumikerkulturkreis" onClick={() => setModalData({
                                title: arch.title,
                                url: arch.file.data.url,
                                dismiss: setModalData
                            })}>{arch.title}</a>
                        </Row>
                    })
            }
            </div>
        </PageBody>
    </Card>
    {
        modalData ? <PdfDialog {...modalData}/>: null
    }
    </React.Fragment>;
}

export const Archive = connect(mapStateToProps, null)(withTranslation('common')(_Component));