import * as React from 'react'
import { connect } from 'react-redux'
import {Card} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import ReactMarkdown from "react-markdown";
import {ApplicationState} from "store";
import {UNDEFINED_CONTENT} from "types";
import * as board from "services/reducers/board";
import {PageHeader} from "components/PageHeader";
import {PageBody} from "components/PageBody";

interface StateToProps {
    name?: string | null
    portrait?: string | null
    chairman?: board.State | null
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
    name: state.data.organisation&&state.data.organisation.name,
    portrait: state.data.organisation&&state.data.organisation.portrait,
    chairman: state.data.board&&(state.data.board.find(e => e.chairman)||null)
});

class _Component extends React.PureComponent<StateToProps & WithTranslation> {

    render() {
        const {name, portrait} = this.props;
        return <Card>
            <PageHeader title={name||UNDEFINED_CONTENT}/>
            <PageBody>
                <ReactMarkdown className="w-100 align-middle light pt-2" source={portrait||UNDEFINED_CONTENT} escapeHtml={false} />
            </PageBody>
        </Card>;
    }
}

export const Portrait = connect(mapStateToProps, null)(withTranslation('common')(_Component));