import {ThunkDispatch} from "redux-thunk";
import {AnyAction, combineReducers} from "redux";
import {ActionType, API_PROJECT, API_SERVER} from "types";
import DirectusSDK from "@directus/sdk-js";
import * as organisation from "./reducers/organisation";
import * as board from "./reducers/board";
import * as archive from "./reducers/archive";
import * as seasons from "./reducers/seasons";
import * as events from "./reducers/events";
import * as memberships from "./reducers/memberships";
import * as actual from "./reducers/actual";
import * as press from "./reducers/press";
import {createReducer} from "helper";
import {ApplicationState} from "store";
import {buildMembershipData, contactFormSubmitted, contactFormSubmitting} from "contactData";
import {buildRegistrationData, registrationFormSubmitted, registrationFormSubmitting} from "../registrationData";

export const API = new DirectusSDK({
    url: API_SERVER,
    project: API_PROJECT,
});

export enum ResourceType {
    ORGANISATION = 'organisation',
    BOARD = 'board',
    ARCHIVE = 'archive',
    SEASONS = 'seasons',
    EVENTS = 'events',
    MEMBERSHIPS = 'memberships',
    APPLICATIONS = 'applications',
    REGISTRATIONS = 'registrations',
    PRESS = 'press',
}

// Api stubs
export enum FetchState { FETCHING, SUCCESS, FAILURE  }

export interface FetchAction {
    state: FetchState
    type: ActionType
    params?: any
    payload?: any
}

const fetchBegin = (type:ActionType, params:any):FetchAction => ({
    state: FetchState.FETCHING,
    type: type,
    params: params
});

const fetchSuccess = (type:ActionType, params:any, singleton: boolean, payload:any):FetchAction => ({
    state: FetchState.SUCCESS,
    type: type,
    params: params,
    payload: singleton?payload.data?payload.data[0]:undefined:payload.data
});

const fetchFailure = (type:ActionType, error:Error):FetchAction => ({
    state: FetchState.FAILURE,
    type: type,
    payload: { ...error }
});

// Generic fetch
export async function fetch(dispatch: ThunkDispatch<any,null,AnyAction>, type:ActionType, params: any, singleton: boolean, fetch: Promise<any>) : Promise<any> {

    dispatch(fetchBegin(type, params));
    try {
        const data = await fetch;
        dispatch(fetchSuccess(type, params, singleton, data));
        return data
    } catch (e) {
        dispatch(fetchFailure(type, e));
    }
}

/*
 * This is the root state of the app
 * It contains every substate of the app
 */
export interface State {
    organisation: organisation.State | null
    board: board.State[] | null
    archive: archive.State[] | null
    season: seasons.State | null
    events: events.State[] | null
    memberships: memberships.State[] | null
    actual: actual.State | null
    press: press.State[] | null
}

/*
 * initialState of the app
 */
export const initialState: State = {
    organisation: null,
    board: null,
    archive: null,
    season: null,
    events: null,
    memberships: null,
    actual: null,
    press: null
};

const organisationReducer = createReducer(null, {
    [ActionType.FETCH_ORGANISATION]: organisation.reducer
});

const boardReducer = createReducer([], {
    [ActionType.FETCH_BOARD]: board.reducer
});

const archiveReducer = createReducer([], {
    [ActionType.FETCH_ARCHIVE]: archive.reducer
});

const seasonsReducer = createReducer(null, {
    [ActionType.FETCH_SEASONS]: seasons.reducer
});

const eventsReducer = createReducer([], {
    [ActionType.FETCH_EVENTS]: events.reducer
});

const membershipsReducer = createReducer([], {
    [ActionType.FETCH_MEMBERSHIPS]: memberships.reducer
});

const actualReducer = createReducer(null, {
    [ActionType.FETCH_ACTUAL_FILE]: actual.reducer
});

const pressReducer = createReducer([], {
    [ActionType.FETCH_PRESS]: press.reducer
});

/*
 * Root reducer of the app
 * Returned reducer will be of type Reducer<State>
 */
export const reducer = combineReducers<State>({
    organisation: organisationReducer,
    board: boardReducer,
    archive: archiveReducer,
    season: seasonsReducer,
    events: eventsReducer,
    memberships: membershipsReducer,
    actual: actualReducer,
    press: pressReducer
});

export interface MembershipData {
    title?: string;
    name?: string;
    street?: string;
    address_complement?: string;
    zipcode?: string;
    city?: string;
    email?: string;
    phone?: string;
    comments?: string;
}

export function submitMembership() : any {

    return async function(dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) {

        dispatch(contactFormSubmitting());
        const configuration: MembershipData | undefined = buildMembershipData(getState().contactData);
        const result = await fetch(dispatch, ActionType.SEND_APPLICATION, {}, true, API.createItem(ResourceType.APPLICATIONS, configuration));
        if (result) {
            dispatch(contactFormSubmitted());
        }
    }
}

export interface RegistrationData {
    title?: string;
    name?: string;
    address?: string;
    zipcode?: string;
    city?: string;
    email?: string;
    phone?: string;
    count_members: number;
    count_non_members: number;
    amount: number;
    comments?: string;
}

export function submitRegistration() : any {

    return async function(dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) {

        dispatch(registrationFormSubmitting());
        const configuration: RegistrationData | undefined = buildRegistrationData(getState().registrationData);
        const result = await fetch(dispatch, ActionType.SEND_REGISTRATION, {}, true, API.createItem(ResourceType.REGISTRATIONS, configuration));
        if (result) {
            dispatch(registrationFormSubmitted());
        }
    }
}

export function assetDownloadURL(private_hash: string) : string {

    return API.url + "/api/assets/" + private_hash + "?key=thumbnail";
}
