import * as React from 'react'
import { connect } from 'react-redux'
import {Card, Col, Label, Row} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {ApplicationState} from "store";
import {NATURAL_SORT_COMPARE_OPTIONS, UNDEFINED_CONTENT} from "types";
import * as events from "services/reducers/events";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import {PageHeader} from "components/PageHeader";
import {PageBody} from "components/PageBody";
import {getVisibleSeasonEvents} from "selectors";

interface StateToProps {
    title?: string | null
    events?: events.State[] | null
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
    title: state.data.season&&state.data.season.name,
    events: getVisibleSeasonEvents(state)
});

class _Component extends React.PureComponent<StateToProps & WithTranslation> {

    render() {
        const {t, title, events} = this.props;
        return <Card>
            <PageHeader title={title||UNDEFINED_CONTENT}/>
            <PageBody>
                {
                    events?
                        events
                            .sort((i1:events.State, i2:events.State) => (i1.when||'').localeCompare(i2.when||'', undefined, NATURAL_SORT_COMPARE_OPTIONS))
                            .map((event:events.State, index: number) => {
                                const when: moment.Moment = moment(event.when);
                                const isWholeDay: boolean = when.format('LT')==='00:00';
                                const isWholeMonth: boolean = event.whole_month;
                                const isLastEvent: boolean = index === events.length-1;
                                const isMemberOnly: boolean = event.only_members;
                                const isNeedRegistration: boolean = event.requires_registration;
                                const isOnlineRegistration: boolean = event.online_registration;
                                const isCancelled: boolean = event.cancelled;
                                const isPostponed: boolean = event.postponed;
                                return <Row className={(isLastEvent?'':'bodyslice ') + 'm-0 pt-3'} key={event.id}>
                                    <Col className={"p-0 col-3 test-left key-text"}>
                                        {
                                            isWholeMonth?
                                                <Label className={"w-100 m-0"}>{'Im ' + when.format('MMMM YYYY')}</Label>:
                                                <Label className={"w-100 m-0"}>{when.format('dddd') + ', ' + when.format('LL')}</Label>
                                        }
                                        {
                                            isWholeDay?
                                                null:
                                                <Label className={"w-100 pt-1 m-0" + (event.location?"pb-1":"pb-3")}>{t('content.event-time', {time: when.format('LT')})}</Label>
                                        }
                                        {
                                            event.location?
                                                <Label className={"w-100 pt-2 m-0 pb-3"}>{event.location.name}<br/>{"(" + event.location.city + ")"}</Label>
                                                :null
                                        }
                                    </Col>
                                    <Col className={"py-0 pr-0 pl-2 col-9 normal-text"}>
                                        {
                                            isCancelled?
                                                <div className="ribbon d-flex align-items-center"><span>{t('content.cancelled')}</span></div>
                                                :null
                                        }
                                        {
                                            !isCancelled && isPostponed?
                                                <div className="ribbon postponed d-flex align-items-center"><span>{t('content.postponed')}</span></div>
                                                :null
                                        }
                                        <Label className={"w-100 m-0 title-text"}>{event.title}</Label>
                                        <ReactMarkdown className="w-100 align-middle light pt-2"
                                                       source={(event.description||'').replace(/(?:\r|\n|\\n|\\r)/g, '<br>')}
                                                       escapeHtml={false} />
                                        {
                                            isMemberOnly?
                                                <Label className={"w-100 m-0 title-text" + (isNeedRegistration?"":"pb-3")}>{t('content.member-only')}</Label>:
                                                null
                                        }
                                        {
                                            isNeedRegistration?
                                                <React.Fragment>
                                                    <Label className={"w-100 m-0 title-text " + (isOnlineRegistration?" pb-1":" pb-3")}>{t('content.need-registration')}</Label>
                                                    {
                                                        isOnlineRegistration?
                                                            <div className={'w-100 m-0 pb-3'}><span>{t('content.online-registration-first')}</span><a className={'title-text'} href={"/registration"}>{t('content.online-registration-link')}</a><span>{t('content.online-registration-last')}</span></div>:
                                                            null
                                                    }
                                                </React.Fragment>:
                                                null
                                        }
                                    </Col>
                                </Row>
                                }
                            )
                        :null
                }
            </PageBody>
        </Card>;
    }
}

export const Saison = connect(mapStateToProps, null)(withTranslation('common')(_Component));