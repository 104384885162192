import * as React from 'react'
import {ReactNode} from 'react'
import {connect} from 'react-redux'
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {DEFAULT_COUNTRIES, VALUE_ERROR_SUFFIX, VALUE_NORMALIZED_SUFFIX} from "types";
import {Map} from 'immutable';
import {InputType} from "reactstrap/lib/Input";
import {ApplicationState} from "store";
import * as EmailValidator from 'email-validator';
import {asBoolean, asString} from "helper";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import {
    buildFormField,
    registrationFormChange,
    RegistrationFormField,
    RegistrationStateData
} from "../registrationData";

interface ComponentProps {
    showLabel: Map<string, boolean>
}

interface StateToProps {
    registrationData: RegistrationStateData;
}

interface DispatchToProps {
    onRegistrationChange: (data: Object) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
    onRegistrationChange: (data: Object) => dispatch(registrationFormChange(data))
});

const mapStateToProps = (state: ApplicationState, props: StateToProps): ComponentProps => ({
    showLabel: computeInputLabels(state.contactData.contact),
});

function computeInputLabels(form: Object): Map<string, boolean> {
    let showLabel: Map<string, boolean> = Map<string, boolean>();
    for (let field in RegistrationFormField) {
        if (form[RegistrationFormField[field]]) {
            showLabel = showLabel.set(RegistrationFormField[field], true);
        }
    }
    return showLabel;
}

function isFieldInvalid(field: RegistrationFormField, form: Object): boolean {
    return !!form[field + VALUE_ERROR_SUFFIX];
}

function isFieldMandatory(form: Object, field: RegistrationFormField): boolean {
    switch (field) {
        case RegistrationFormField.TITLE:
        case RegistrationFormField.COMMENTS:
        case RegistrationFormField.COUNT_NON_MEMBERS:
        case RegistrationFormField.AMOUNT:
            return false;
        default:
            return true;
    }
}

function isFieldVisible(field: RegistrationFormField): boolean {
    switch (field) {
        default:
            return true;
    }
}

function validateFieldValue(props: RegistrationFormValidationProps, field: RegistrationFormField, value: string | boolean): string | undefined {
    const {t, registrationData} = props;
    switch (field) {
        case RegistrationFormField.EMAIL:
            return value && !EmailValidator.validate(asString(value)) ? t('registration.invalidemail') :
                validateMandatoryField(props, field, asString(value));
        case RegistrationFormField.PHONE:
            return value && !isValidPhoneNumber(value as string) ? t('registration.invalidphone') :
                validateMandatoryField(props, field, asString(value));
        case RegistrationFormField.COUNT_MEMBERS:
            return ~~value<=0 && (!registrationData.registration[RegistrationFormField.COUNT_NON_MEMBERS] || registrationData.registration[RegistrationFormField.COUNT_NON_MEMBERS]<=0) ? t('registration.missingvalue') :
                undefined;
        case RegistrationFormField.COUNT_NON_MEMBERS:
            return ~~value<=0 && (!registrationData.registration[RegistrationFormField.COUNT_MEMBERS] || registrationData.registration[RegistrationFormField.COUNT_MEMBERS]<=0) ? t('registration.missingvalue') :
                undefined;
        default:
            return validateMandatoryField(props, field, asString(value));
    }
}

function validateMandatoryField(props: RegistrationFormValidationProps, field: RegistrationFormField, value: string): string | undefined {
    const {t, registrationData} = props;
    return isFieldMandatory(registrationData.registration, field) && !value ? t('registration.missingvalue') : undefined;
}

class _Component extends React.PureComponent<StateToProps & ComponentProps & DispatchToProps & WithTranslation> {

    constructor(props: StateToProps & ComponentProps & DispatchToProps & WithTranslation, context: any) {
        super(props, context);
        this.formField = this.formField.bind(this);
        this.fieldData = this.fieldData.bind(this);
        this.reportField = this.reportField.bind(this);
        this.resolveRelations = this.resolveRelations.bind(this);
    }

    /**
     * Utility to build the a form field
     * @param field
     * @param inputType
     * @param rowCount
     */
    formField(field: RegistrationFormField, inputType: InputType, rowCount?: number): ReactNode {
        const {t, registrationData, onRegistrationChange, showLabel} = this.props;
        const label: string = t('registration.' + field);
        const invalid: boolean = isFieldInvalid(field, registrationData.registration);
        const mandatory: boolean = isFieldMandatory(registrationData.registration, field);
        return <React.Fragment>
            {invalid ?
                <label htmlFor={field}
                       className={"form-label mb-0 show error"}>{registrationData.registration[field + VALUE_ERROR_SUFFIX]}</label> :
                inputType === 'checkbox'?
                    null :
                    <label htmlFor={field}
                           className={"form-label mb-0 " + (showLabel.get(field) ? 'show' : '')}>{label}</label>
            }
            {
                field === RegistrationFormField.PHONE ?
                    <PhoneInput id={field}
                                placeholder={t('registration.field' + (mandatory ? 'required' : 'optional'), {field: label})}
                                className={"form-control text-normal-details form-control-lg " + (invalid ? "field-error" : "")}
                                readOnly={false}
                                value={asString(registrationData.registration[field])}
                                defaultCountry={'CH'}
                                countryOptionsOrder={DEFAULT_COUNTRIES}
                                onChange={(value: string) => onRegistrationChange(this.fieldData(field, value))}/> :
                    field === RegistrationFormField.COUNT_MEMBERS || field === RegistrationFormField.COUNT_NON_MEMBERS ?
                        <Input type={inputType} id={field} min={0} max={15}
                               className={"form-control text-normal-details form-control-lg " + (invalid ? "field-error" : "")}
                               readOnly={false}
                               value={asString(registrationData.registration[field])}
                               onChange={(e) => onRegistrationChange(this.coupledFieldData(field, e.target.value))}/>:
                        inputType === 'textarea' ?
                            <textarea id={field}
                                      placeholder={t('registration.field' + (mandatory ? 'required' : 'optional'), {field: label})}
                                      className={"form-control text-normal-details form-control-lg " + (invalid ? "field-error" : "")}
                                      readOnly={false}
                                      value={asString(registrationData.registration[field])}
                                      onChange={(e) => onRegistrationChange(this.fieldData(field, e.target.value))}
                                      rows={rowCount}/> :
                            inputType === 'checkbox' ?
                                <React.Fragment>
                                    <Input type={'checkbox'} id={field} className={""}
                                           checked={asBoolean(registrationData.registration[field])}
                                           onChange={(e) => onRegistrationChange(this.fieldData(field, e.target.checked))}/>
                                    <Label className="form-check-label" htmlFor={field}>{label}</Label>
                                </React.Fragment> :
                                <Input type={inputType} id={field}
                                       placeholder={t('registration.field' + (mandatory ? 'required' : 'optional'), {field: label})}
                                       className={"form-control text-normal-details form-control-lg " + (invalid ? "field-error" : "")}
                                       readOnly={false}
                                       value={asString(registrationData.registration[field])}
                                       onChange={(e) => onRegistrationChange(this.fieldData(field, e.target.value))}/>
            }
        </React.Fragment>
    }

    fieldData(field: RegistrationFormField, value: string | boolean): Object {
        const error: string | undefined = validateFieldValue(this.props, field, value);
        return {
            ...buildFormField(field, value),
            // Add error part
            [field + VALUE_ERROR_SUFFIX]: error
        }
    }

    coupledFieldData(field: RegistrationFormField, value: string | boolean): Object {
        const error: string | undefined = validateFieldValue(this.props, field, value);
        const coupledField = field===RegistrationFormField.COUNT_NON_MEMBERS?RegistrationFormField.COUNT_MEMBERS:RegistrationFormField.COUNT_NON_MEMBERS
        return {
            ...buildFormField(field, value),
            // Add error part
            [field + VALUE_ERROR_SUFFIX]: error,
            [coupledField + VALUE_ERROR_SUFFIX]: error
        }
    }

    resolveRelations(field: RegistrationFormField, value: any): any {
        return value;
    }

    titleField(field: RegistrationFormField): ReactNode {
        const {t} = this.props;
        return <Row className="no-gutters pt-4">
                <Col className={"col-sm"}><Label
                    className={"no-gutters mb-0"}>{t('registration.' + field + '_title')}</Label></Col>
            </Row>
    }

    totalField(field: RegistrationFormField): ReactNode {
        const {t, registrationData} = this.props;
        const value: any = registrationData.registration[field];
        return <Row className="no-gutters pt-4">
            <Col className={"col-sm-12 font-weight-bold"}><Label
                className={"no-gutters mb-0"}>{t('registration.amount_title', {amount: value})}</Label></Col>
        </Row>
    }

    amountField(field: RegistrationFormField): ReactNode {
        const {t, registrationData} = this.props;
        const value: any = registrationData.registration[field + VALUE_NORMALIZED_SUFFIX];
        return <Row className="no-gutters pt-2">
                <Col className={"col-sm-3"}><Label
                    className={"report-label"}>{t('registration.' + field + '_title_short')}</Label></Col>
                <Col className={"col-sm-9 text-normal-details text-pre-wrap"}>{value}</Col>
            </Row>
    }

    reportField(field: RegistrationFormField): ReactNode {
        const {t, registrationData} = this.props;
        const value: any = registrationData.registration[field + VALUE_NORMALIZED_SUFFIX];
        const resolved: any = this.resolveRelations(field, value);
        return resolved ?
                <Row className="no-gutters pt-2">
                    <Col className={"col-sm-3"}><Label
                        className={"report-label"}>{t('registration.' + field)}</Label></Col>
                    <Col className={"col-sm-9 text-normal-details text-pre-wrap"}>{resolved}</Col>
                </Row> :
            null
    }

    render() {
        const {registrationData, t} = this.props;
        return <React.Fragment>
            <Row className="no-gutters normal-text pt-2">
                <Label>{t(registrationData.formSubmitted ? 'registration.submittedtext' : 'registration.text')}</Label>
            </Row>
            {registrationData.formSubmitted ?
                <React.Fragment>
                    {this.reportField(RegistrationFormField.TITLE)}
                    {this.reportField(RegistrationFormField.NAME)}
                    {this.reportField(RegistrationFormField.ADDRESS)}
                    {this.reportField(RegistrationFormField.ZIPCODE)}
                    {this.reportField(RegistrationFormField.CITY)}
                    {this.reportField(RegistrationFormField.EMAIL)}
                    {this.reportField(RegistrationFormField.PHONE)}
                    {this.reportField(RegistrationFormField.COMMENTS)}
                    {this.amountField(RegistrationFormField.COUNT_MEMBERS)}
                    {this.amountField(RegistrationFormField.COUNT_NON_MEMBERS)}
                    {this.totalField(RegistrationFormField.AMOUNT)}
                </React.Fragment> :
                <Form>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(RegistrationFormField.TITLE, "text")}</FormGroup>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(RegistrationFormField.NAME, "text")}</FormGroup>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(RegistrationFormField.ADDRESS, "text")}</FormGroup>
                    <Row className="no-gutters">
                        <FormGroup
                            className={"col-sm-3 pr-sm-2 mb-2 normal-text"}>{this.formField(RegistrationFormField.ZIPCODE, "number")}</FormGroup>
                        <FormGroup
                            className={"col-sm-9 pl-sm-2 mb-2 normal-text"}>{this.formField(RegistrationFormField.CITY, "text")}</FormGroup>
                    </Row>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(RegistrationFormField.EMAIL, "email")}</FormGroup>
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(RegistrationFormField.PHONE, "tel")}</FormGroup>
                    {this.titleField(RegistrationFormField.COUNT_MEMBERS)}
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(RegistrationFormField.COUNT_MEMBERS, "number")}</FormGroup>
                    {this.titleField(RegistrationFormField.COUNT_NON_MEMBERS)}
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(RegistrationFormField.COUNT_NON_MEMBERS, "number")}</FormGroup>
                    {this.totalField(RegistrationFormField.AMOUNT)}
                    <FormGroup className={"mb-2 normal-text"}>{this.formField(RegistrationFormField.COMMENTS, "textarea", 5)}</FormGroup>
                </Form>
            }
        </React.Fragment>
    }
}

export interface RegistrationFormStateProps extends StateToProps {
}

export interface RegistrationFormValidationProps extends RegistrationFormStateProps, WithTranslation, DispatchToProps {
}

export function computeAllowSubmit(props: RegistrationFormStateProps): boolean {
    const {registrationData} = props;
    return Object.keys(RegistrationFormField).map(key => RegistrationFormField[key]).reduce((prev: boolean, curr: RegistrationFormField) =>
        prev && (!isFieldVisible(curr) || !isFieldInvalid(curr, registrationData.registration)), true)
}

export function validateForm(props: RegistrationFormValidationProps): boolean {
    const {registrationData, onRegistrationChange} = props;
    const validationObject: object =
        Object.keys(RegistrationFormField).map(key => RegistrationFormField[key]).reduce((prev: Object, curr: RegistrationFormField) => {
            const value: string | number | boolean | undefined = registrationData.registration[curr];
            const error: string | undefined = validateFieldValue(props, curr, (value && value.toString()) || '');
            return error ? {...prev, [curr + VALUE_ERROR_SUFFIX]: error} : prev;
        }, {});
    onRegistrationChange(validationObject);
    return Object.keys(validationObject).length === 0;
}

export const RegistrationForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(_Component));