import * as React from 'react'
import { connect } from 'react-redux'
import {Card, CardImg, Col, Label, Row} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {ApplicationState} from "store";
import * as board from "services/reducers/board";
import {NATURAL_SORT_COMPARE_OPTIONS} from "types";
import {assetDownloadURL} from "services";
import {PageHeader} from "components/PageHeader";
import {PageBody} from "components/PageBody";

interface StateToProps {
    board?: board.State[] | null
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
    board: state.data.board
});

class _Component extends React.PureComponent<StateToProps & WithTranslation> {

    render() {
        const {t, board} = this.props;
        return <Card>
            <PageHeader titleKey={'content.board-title'}/>
            <PageBody>
                {
                    board&&board
                        .sort((b1:board.State, b2:board.State) =>
                            (+b2.chairman - (+b1.chairman)) ||
                            (b1.lastname||'').localeCompare(b2.lastname||'', undefined, NATURAL_SORT_COMPARE_OPTIONS))
                        .map((b, index) => <Row key={index} className={index<board.length-1?"pb-3":""}>
                                <Col className={"col-3"}>
                                    <CardImg src={assetDownloadURL(b.picture.private_hash)} alt={b.picture.private_hash} className=""/>
                                </Col>
                                <Col className={"col-9 d-flex align-items-center"}>
                                    <div className={"w-100"}>
                                        <Label className={"w-100 m-0 board-text"}>{t('content.board-item', b)}</Label>
                                        <a className={'w-100 m-0 key-text'} href={"mailto:"+ b.email}>{t('content.board-contact', b)}</a>
                                    </div>
                                </Col>
                            </Row>
                        )
                }
            </PageBody>
        </Card>;
    }
}

export const Board = connect(mapStateToProps, null)(withTranslation('common')(_Component));