import * as React from "react";
import {connect} from 'react-redux'
import {WithTranslation, withTranslation} from "react-i18next";
import {ActionType} from "types";
import {ApplicationState} from "store";
import {Col, Container, Row} from "reactstrap";
import {Portrait} from "components/Portrait";
import {Home} from "components/Home";
import {Saison} from "components/Saison";
import {Actual} from "components/Actual";
import {Board} from "components/Board";
import {Membership} from "components/Membership";
import {Archive} from "components/Archive";
import {SideBar} from "components/SideBar";
import ReactStickyBox from "react-sticky-box";
import {Registration} from "../components/Registration";
import {Privacy} from "../components/Privacy";
import {Press} from "components/Press";

interface StateToProps {
    location?: string
}

interface ComponentStateToProps extends StateToProps {
    actualWidth?: number
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
    location: state.location ? state.location.type : '',//FIXME
});

class _RootContainer extends React.Component<ComponentStateToProps & WithTranslation> {

    constructor(props: ComponentStateToProps & WithTranslation, context: any) {
        super(props, context);
        this.state = {
            width: props.actualWidth
        };
    }

    scenes = {
        [ActionType.PAGE_HOME]: () => <Home/>,
        [ActionType.PAGE_SAISON]: () => <Saison/>,
        [ActionType.PAGE_ACTUAL]: () => <Actual/>,
        [ActionType.PAGE_REGISTRATION]: () => <Registration/>,
        [ActionType.PAGE_PORTRAIT]: () => <Portrait/>,
        [ActionType.PAGE_BOARD]: () => <Board/>,
        [ActionType.PAGE_MEMBERSHIP]: () => <Membership/>,
        [ActionType.PAGE_PRESS]: () => <Press/>,
        [ActionType.PAGE_ARCHIVE]: () => <Archive/>,
        [ActionType.PAGE_PRIVACY]: () => <Privacy/>,
    };

    shouldComponentUpdate(nextProps: Readonly<ComponentStateToProps & WithTranslation>) {
        return this.props.actualWidth!==nextProps.actualWidth ||
            this.props.location!==nextProps.location;
    }

    render() {
        return this.props.actualWidth&&this.props.actualWidth >= 992 ?
            <Container fluid={false} className="d-none d-lg-block py-3 px-0">
                <Row className={"no-gutters"}>
                    <Col className="col-md-3 pr-2 d-print-none">
                        <div className={"h-100 sidebar rounded"}>
                            <ReactStickyBox offsetTop={20} offsetBottom={20}>
                                <SideBar isBurger={false} />
                            </ReactStickyBox>
                        </div>
                    </Col>
                    <Col className="col-md-9 pl-2 col-print-w100">
                    {
                        this.props.location?
                            this.scenes[this.props.location]():
                            null
                    }
                    </Col>
                </Row>
            </Container>:
            <Container id="outer-container" fluid={true} className="main-content d-lg-none p-0">
                <SideBar isBurger={true} pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
                <main id="page-wrap">
                {
                    this.props.location?
                        this.scenes[this.props.location]():
                        null
                }
                </main>
            </Container>;
    }
}

export const RootContainer = connect(mapStateToProps, null)(withTranslation('common')(_RootContainer));
