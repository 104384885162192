import * as React from 'react'
import { connect } from 'react-redux'
import {
    Card,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {ApplicationState} from "store";
import * as memberships from "services/reducers/memberships";
import {computeAllowSubmit, ContactForm, validateForm} from "components/ContactForm";
import {ContactData, contactFormChange, contactFormReady} from "contactData";
import {AsyncButton} from "components/AsyncButton";
import {submitMembership} from "services";
import {PageHeader} from "components/PageHeader";
import {PageBody} from "components/PageBody";

interface StateToProps {
    memberships: memberships.State[] | null,
    contactData: ContactData
}

const mapStateToProps = (state: ApplicationState): StateToProps => ({
    memberships: state.data.memberships,
    contactData: state.contactData
});

interface DispatchToProps {
    onMembershipChange: (data:Object) => void
    onMembershipSubmit: () => void
    submitMembership: () => void
    editMembership: () => void
}

const mapDispatchToProps = (dispatch: any) : DispatchToProps => ({
    onMembershipChange: (data:Object) => dispatch(contactFormChange(data)),
    onMembershipSubmit: () => dispatch(contactFormReady(true)),
    submitMembership: () => dispatch(submitMembership()),
    editMembership: () => dispatch(contactFormReady(false))
});

class _Component extends React.PureComponent<StateToProps & DispatchToProps & WithTranslation> {

    render() {
        const {t, contactData, memberships, onMembershipSubmit, editMembership, submitMembership} = this.props;
        return <React.Fragment>
            <Card>
                <PageHeader titleKey={'content.membership-title'}/>
                <PageBody>
                    <Label className={"w-100 m-0 normal-text pt-3"}>{t('content.membership-intro')}</Label>
                    <Label className={"w-100 m-0 text-uppercase title-text pt-3 pb-2"}>{t('content.membership-cost-title')}</Label>
                    {
                        memberships&&memberships
                            .sort((a, b) => a.id - b.id)
                            .map(m => {
                                return <Row key={m.id}>
                                    <Col className={"col-6 col-lg-4"}>
                                        <Label className={"m-0 normal-text"}>{m.type}</Label>
                                    </Col>
                                    <Col className={"col-6 col-lg-2 text-right"}>
                                        {m.allow_more?
                                            <Label className={"m-0 normal-text"}>{t('content.from_price', m)}</Label>:
                                            <Label className={"m-0 normal-text"}>{t('content.price', m)}</Label>
                                        }
                                    </Col>
                                </Row>
                            })
                    }
                    {
                        memberships?
                            <React.Fragment>
                                <Label className={"w-100 m-0 normal-text pt-2"}>{t('content.membership-info')}</Label>
                                <Label className={'w-100 m-0 text-uppercase title-text pt-3'}>{t('content.membership-registration-title')}</Label>
                                <ContactForm memberships={memberships} contactData={contactData}/>
                                <Row className="no-gutters py-2 d-print-none">
                                    <Col className="px-0 col-md-6 align-self-center">
                                        {contactData.formSubmitted ?
                                            null :
                                            <AsyncButton
                                                disabled={contactData.formSubmitting || !computeAllowSubmit(this.props)}
                                                iconClassName="far fa-check-circle" buttonClassName='w-100'
                                                submitting={contactData.formSubmitting} labelTKey='membership.do'
                                                onSubmit={() => validateForm(this.props) && onMembershipSubmit()}/>
                                        }
                                    </Col>
                                </Row>
                            </React.Fragment>:
                            null
                    }
                </PageBody>
            </Card>
            {
                contactData.formReady ?
                    <Modal isOpen={!contactData.formSubmitted}>
                        <ModalHeader>
                            <div className={"title-normal-uppercase"}>{t('membership.sendtitle')}</div>
                        </ModalHeader>
                        <ModalBody>{t('membership.sendquestion')}</ModalBody>
                        <ModalFooter>
                            <AsyncButton iconClassName="far fa-edit" disabled={contactData.formSubmitting}
                                         submitting={false} labelTKey='membership.continueEdit'
                                         onSubmit={() => editMembership()}/>
                            <AsyncButton iconClassName="far fa-check-circle" disabled={contactData.formSubmitting} buttonClassName='primary'
                                         submitting={contactData.formSubmitting} labelTKey='membership.sendOk'
                                         onSubmit={() => submitMembership()}/>
                        </ModalFooter>
                    </Modal> :
                    null
            }
        </React.Fragment>
    }
}

export const Membership = connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(_Component));