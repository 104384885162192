import {NOT_FOUND, RoutesMap} from 'redux-first-router';
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ApplicationState} from "store";
import {ActionType} from "types";
import {API, fetch, ResourceType} from "services";
import {action as toggleMenu} from 'redux-burger-menu';
import ReactGA from 'react-ga';

const FETCH_BOARD_PARAMS: object = {fields: "*,picture.private_hash"};
const FETCH_ARCHIVE_PARAMS: object = {fields: "*,file.data.*"};
const FETCH_PRESS_PARAMS: object = {fields: "*,article.data.*"};

function hideMenu(dispatch: ThunkDispatch<any,null,AnyAction>, applicationState: ApplicationState) {
    if (applicationState.burgerMenu.isOpen) {
        dispatch(toggleMenu(false));
    }
}

function pageView(page: string) {
    ReactGA.pageview(page);
}

export interface Keys {
}

export const routePaths: RoutesMap<Keys, ApplicationState> = {
    [ActionType.PAGE_HOME]: {
        path: '/',
        thunk: (dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) => {
            hideMenu(dispatch, getState());
            fetch(dispatch, ActionType.FETCH_ORGANISATION, null, true, API.getItems(ResourceType.ORGANISATION));
            pageView(ActionType.PAGE_HOME);
        }
    },
    [ActionType.PAGE_SAISON]: {
        path: '/saison',
        thunk: async (dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) => {
            hideMenu(dispatch, getState());
            var seasons = await fetch(dispatch, ActionType.FETCH_SEASONS, null, true, API.getItems(ResourceType.SEASONS));
            if (seasons&&seasons.data&&seasons.data.length){
                fetch(dispatch, ActionType.FETCH_EVENTS, null, false, API.getItems(ResourceType.EVENTS, {
                    filter: {
                        season: {
                            eq: seasons.data[0].id
                        }
                    },
                    fields: "*,location.*"
                }));
            }
            pageView(ActionType.PAGE_SAISON);
        }
    },
    [ActionType.PAGE_ACTUAL]: {
        path: '/actual',
        thunk: async (dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) => {
            hideMenu(dispatch, getState());
            var seasons = await fetch(dispatch, ActionType.FETCH_SEASONS, null, true, API.getItems(ResourceType.SEASONS));
            if (seasons&&seasons.data&&seasons.data.length){
                fetch(dispatch, ActionType.FETCH_ACTUAL_FILE, null, false, API.getItems(ResourceType.EVENTS, {
                    filter: {
                        season: {
                            eq: seasons.data[0].id
                        },
                        actual: {
                            eq: 1
                        }
                    },
                    fields: "*,detail_file.id,detail_file.checksum,detail_file.data.*"
                }));
            }
            pageView(ActionType.PAGE_ACTUAL);
        }
    },
    [ActionType.PAGE_REGISTRATION]: {
        path: '/registration',
        thunk: (dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) => {
            hideMenu(dispatch, getState());
//            fetch(dispatch, ActionType.FETCH_MEMBERSHIPS, null, false, API.getItems(ResourceType.MEMBERSHIPS));
            pageView(ActionType.PAGE_REGISTRATION);
        }
    },
    [ActionType.PAGE_PORTRAIT]: {
        path: '/portrait',
        thunk: (dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) => {
            hideMenu(dispatch, getState());
            fetch(dispatch, ActionType.FETCH_ORGANISATION, null, true, API.getItems(ResourceType.ORGANISATION));
            fetch(dispatch, ActionType.FETCH_BOARD, null, false, API.getItems(ResourceType.BOARD, FETCH_BOARD_PARAMS));
            pageView(ActionType.PAGE_PORTRAIT);
        }
    },
    [ActionType.PAGE_BOARD]: {
        path: '/board',
        thunk: (dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) => {
            hideMenu(dispatch, getState());
            fetch(dispatch, ActionType.FETCH_BOARD, null, false, API.getItems(ResourceType.BOARD, FETCH_BOARD_PARAMS));
            pageView(ActionType.PAGE_BOARD);
        }
    },
    [ActionType.PAGE_MEMBERSHIP]: {
        path: '/membership',
        thunk: (dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) => {
            hideMenu(dispatch, getState());
            fetch(dispatch, ActionType.FETCH_MEMBERSHIPS, null, false, API.getItems(ResourceType.MEMBERSHIPS));
            pageView(ActionType.PAGE_MEMBERSHIP);
        }
    },
    [ActionType.PAGE_PRESS]: {
        path: '/press',
        thunk: (dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) => {
            hideMenu(dispatch, getState());
            fetch(dispatch, ActionType.FETCH_PRESS, null, false, API.getItems(ResourceType.PRESS, FETCH_PRESS_PARAMS));
            pageView(ActionType.PAGE_PRESS);
        }
    },
    [ActionType.PAGE_ARCHIVE]: {
        path: '/archive',
        thunk: (dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) => {
            hideMenu(dispatch, getState());
            fetch(dispatch, ActionType.FETCH_ARCHIVE, null, false, API.getItems(ResourceType.ARCHIVE, FETCH_ARCHIVE_PARAMS));
            pageView(ActionType.PAGE_ARCHIVE);
        }
    },
    [ActionType.PAGE_PRIVACY]: {
        path: '/privacy',
        thunk: (dispatch: ThunkDispatch<any,null,AnyAction>, getState: () => ApplicationState) => {
            hideMenu(dispatch, getState());
            pageView(ActionType.PAGE_PRIVACY);
        }
    },
    [NOT_FOUND]: 'NotFound'
};

export function selectPageAction(page: ActionType.PAGE_HOME | ActionType.PAGE_PORTRAIT | ActionType.PAGE_SAISON |ActionType.PAGE_ARCHIVE | ActionType.PAGE_MEMBERSHIP | ActionType.PAGE_BOARD | ActionType.PAGE_ACTUAL | ActionType.PAGE_REGISTRATION | ActionType.PAGE_PRIVACY | ActionType.PAGE_PRESS): any {
    return {
        type: page,
    }
}