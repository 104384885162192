import React, {Dispatch, SetStateAction, useRef, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Document, Page} from "react-pdf";

export interface PdfDialogData {
    title: string
    url: string
    dismiss: Dispatch<SetStateAction<PdfDialogData|null>>
}

interface PdfState {
    numPages: number,
    width: number
}

const PdfDialog: React.FC<PdfDialogData|null> = (props) => {
    const {t} = useTranslation();
    const divRef = useRef<HTMLDivElement>(null);
    const [pdfState, setPdfState] = useState<PdfState>({
        numPages: -1,
        width: -1
    });

    return <Modal show={!!props} onHide={() => props.dismiss(null)}
                   backdrop={"static"} keyboard={false} className={'fullScreen'}>
        <Modal.Header closeButton={true}>
            <Modal.Title className={"h5"}>{t('pdf.title', {title: props.title})}</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={divRef} className={'p-0'}>
            <Document className={"archive"} file={{url: props.url}} onLoadSuccess={ pdf => pdfState.width<0?setPdfState({
                numPages: pdf.numPages||0,
                width: (divRef.current&&divRef.current.offsetWidth)||0
            }):null}>
                {
                    pdfState.numPages > 0 ? <Page pageNumber={1} width={pdfState.width}/> : null
                }
                {
                    pdfState.numPages > 1 ? <Page pageNumber={2} width={pdfState.width}/> : null
                }
            </Document>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => props.dismiss(null)}>{t('pdf.close')}</Button>
        </Modal.Footer>
    </Modal>
}

export default PdfDialog;
