import {FetchAction, FetchState} from "services";
import {ActionType} from "types";

export interface State {
    id: number,
    file:  {
        data: {
            full_url: string,
            url: string
        }
    },
    title: string
}

export function reducer(state: State[] | null = null, action: FetchAction) {
    switch (action.state) {
        case FetchState.SUCCESS: {
            switch (action.type) {
                case ActionType.FETCH_ARCHIVE: {
                    return action.payload
                }
                default:
                    return state;
            }
        }
        default:
            return state
    }
}