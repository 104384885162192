import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import React from 'react';
import { configureStore } from './store'
import './assets/stylesheets/sass/bootstrap.scss'
import './assets/stylesheets/sass/fontawesome.scss'
import 'react-phone-number-input/style.css'
import './assets/stylesheets/sass/app.scss'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next';
import i18next from 'translations'
import {RootContainer} from "containers/RootContainer";
import registerServiceWorker from './registerServiceWorker';
import {render} from "react-dom";
import ReactResizeDetector from "react-resize-detector";
import ReactGA from 'react-ga';

// Force refresh when new controller available
let refreshing: boolean;
navigator.serviceWorker.addEventListener('controllerchange', function (event) {

    if (refreshing) return;
    refreshing = true;
    window.location.reload();
});

ReactGA.initialize('UA-8371322-1');
ReactGA.set({ anonymizeIp: true });

const store = configureStore();

/*if (process.env.NODE_ENV !== 'production') {
    const {whyDidYouUpdate} = require('why-did-you-update');
    whyDidYouUpdate(React)
}*/
render(
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
          <ReactResizeDetector
              handleWidth
              render={props => <RootContainer actualWidth={props.width}/>}/>
        </Provider>
    </I18nextProvider>,
    document.getElementById('root') as HTMLElement
);

registerServiceWorker();