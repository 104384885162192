export enum ActionType {
    FETCH_ORGANISATION = 'zuku@FETCH_ORGANISATION',
    FETCH_BOARD = 'zuku@FETCH_BOARD',
    FETCH_SEASONS = 'zuku@FETCH_SEASONS',
    FETCH_EVENTS = 'zuku@FETCH_EVENTS',
    FETCH_PRESS = 'zuku@FETCH_PRESS',
    FETCH_ARCHIVE = 'zuku@FETCH_ARCHIVE',
    FETCH_MEMBERSHIPS = 'zuku@FETCH_MEMBERSHIPS',
    FETCH_ACTUAL_FILE = 'zuku@FETCH_ACTUAL_FILE',
    SEND_APPLICATION = 'zuku@SEND_APPLICATION',
    SEND_REGISTRATION = 'zuku@SEND_REGISTRATION',

    CONTACT_FORMCHANGE = 'zuku@CONTACT_FORMCHANGE',
    CONTACT_FORMREADY = 'zuku@CONTACT_FORMREADY',
    CONTACT_FORMSUBMITTING = 'zuku@CONTACT_FORMSUBMITTING',
    CONTACT_FORMSUBMITTED = 'zuku@CONTACT_FORMSUBMITTED',
    CONTACT_FORMCANNOTSUBMIT = 'zuku@CONTACT_FORMCANNOTSUBMIT',

    REGISTRATION_FORMCHANGE = 'zuku@REGISTRATION_FORMCHANGE',
    REGISTRATION_FORMREADY = 'zuku@REGISTRATION_FORMREADY',
    REGISTRATION_FORMSUBMITTING = 'zuku@REGISTRATION_FORMSUBMITTING',
    REGISTRATION_FORMSUBMITTED = 'zuku@REGISTRATION_FORMSUBMITTED',
    REGISTRATION_FORMCANNOTSUBMIT = 'zuku@REGISTRATION_FORMCANNOTSUBMIT',

    PAGE_HOME = 'zuku@PAGE_HOME',
    PAGE_SAISON = 'zuku@PAGE_SAISON',
    PAGE_ACTUAL = 'zuku@PAGE_ACTUAL',
    PAGE_PORTRAIT = 'zuku@PAGE_PORTRAIT',
    PAGE_BOARD = 'zuku@PAGE_BOARD',
    PAGE_MEMBERSHIP = 'zuku@PAGE_MEMBERSHIP',
    PAGE_REGISTRATION = 'zuku@PAGE_REGISTRATION',
    PAGE_ARCHIVE = 'zuku@PAGE_ARCHIVE',
    PAGE_PRIVACY = 'zuku@PAGE_PRIVACY',
    PAGE_PRESS = 'zuku@PAGE_PRESS',
}

export const API_SERVER = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'?'https://admin.zumikerkulturkreis.ch':'https://www.zumikerkulturkreis.ch');
export const API_PROJECT = 'api';

export const NATURAL_SORT_COMPARE_OPTIONS : object = {numeric: true, sensitivity: 'base'};

export const NUMBER_FORMAT_DECIMAL = '0,0[.]00';

export const UNDEFINED_CONTENT = '';

export const VALUE_ERROR_SUFFIX = '$error';
export const VALUE_NORMALIZED_SUFFIX = '$norm';

export const DEFAULT_COUNTRIES = ["CH","..."];
