import * as React from 'react'
import {ReactNode} from 'react'
import {connect} from 'react-redux'
import {Card, CardBody, Nav, Navbar, NavbarBrand, NavItem} from "reactstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {selectPageAction} from "routes";
import {ActionType} from "types";
import {NavLink} from "redux-first-router-link";
import {elastic as Menu} from 'react-burger-menu';
import {decorator as reduxBurgerMenu} from 'redux-burger-menu';

const SlideMenu = reduxBurgerMenu(Menu);

interface ComponentProps {
    isBurger?:boolean
    pageWrapId?: string
    outerContainerId?: string
}

interface StateToProps {
}

interface DispatchToProps {
}

const mapDispatchToProps = (dispatch: any) : DispatchToProps => ({
});

class _Component extends React.PureComponent<StateToProps & DispatchToProps & WithTranslation & ComponentProps> {

    constructor(props: StateToProps & DispatchToProps & WithTranslation & ComponentProps, context: any) {
        super(props, context);
        this.menuEntry = this.menuEntry.bind(this);
        this.menuCard = this.menuCard.bind(this);
    }

    menuEntry(code:string, action: ActionType.PAGE_HOME | ActionType.PAGE_PORTRAIT | ActionType.PAGE_SAISON | ActionType.PAGE_ARCHIVE |
        ActionType.PAGE_MEMBERSHIP | ActionType.PAGE_BOARD | ActionType.PAGE_ACTUAL | ActionType.PAGE_REGISTRATION |
        ActionType.PAGE_PRIVACY | ActionType.PAGE_PRESS): ReactNode {
        const {t} = this.props;
        return <NavItem className={"py-3"}>
            <NavLink to={selectPageAction(action)} exact={true} strict={true} activeClassName={"active"} className={"text-uppercase"} >{t(code)}</NavLink>
        </NavItem>
    }

    menuEntryRegistration(code:string, action: ActionType.PAGE_REGISTRATION): ReactNode {
        const {t} = this.props;
        return <NavItem className={"py-3"}>
            <NavLink to={selectPageAction(action)} exact={true} strict={true} activeClassName={"active"} className={"text-uppercase"} >{t(code)}<br/>{t(code + '_topic')}</NavLink>
        </NavItem>
    }

    menuCard(): ReactNode {
        return <Card className={"shadow-none"}>
            <CardBody className="sidebar">
                <Navbar className={"p-0"}>
                    <NavbarBrand href="." className="w-100 pb-6 pt-0"><img src={"logo.png"} alt="logo" height="76"></img></NavbarBrand>
                    <Nav className="flex-column">
                        {this.menuEntry('menu.home', ActionType.PAGE_HOME)}
                        {this.menuEntry('menu.yearlyprogram', ActionType.PAGE_SAISON)}
                        {this.menuEntry('menu.actual', ActionType.PAGE_ACTUAL)}
                        {false && this.menuEntryRegistration('menu.registration', ActionType.PAGE_REGISTRATION)}
                        {this.menuEntry('menu.portrait', ActionType.PAGE_PORTRAIT)}
                        {this.menuEntry('menu.board', ActionType.PAGE_BOARD)}
                        {this.menuEntry('menu.membership', ActionType.PAGE_MEMBERSHIP)}
                        {this.menuEntry('menu.press', ActionType.PAGE_PRESS)}
                        {this.menuEntry('menu.archive', ActionType.PAGE_ARCHIVE)}
                        {this.menuEntry('menu.privacy', ActionType.PAGE_PRIVACY)}
                    </Nav>
                </Navbar>
            </CardBody>
        </Card>
    }

    render() {
        const {pageWrapId, outerContainerId, isBurger}  = this.props;
        return isBurger?
        <SlideMenu pageWrapId={pageWrapId} outerContainerId={outerContainerId}>
            {
                this.menuCard()
            }
        </SlideMenu>:
            this.menuCard()
    }
}

export const SideBar = connect(null, mapDispatchToProps)(withTranslation('common')(_Component));